<template>
    <div class="root">
        <div class="row mt-10" style="display:flex; justify-content: center;">
            <div class="col col-11 col-md-8" style="max-width:700px;">
                <v-card elevation="0" outlined v-for="(u, index) in userList" :index="index" :key="index" class="my-2">
                    <v-card-text class=justify-center>
                        <div class="row">
                            <div class="col col-6" style="display:flex; justify-content: center;">
                                <h2> <a :href="'/' + u.target_channel_name">{{u.target_channel_name}}</a> </h2>
                            </div>
                            <div class="col col-6" style="display:flex; justify-content: center;">
                                <a @click="unlisten(u.target_channel_name)"> stop listening </a>
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
            </div>
        </div>
    </div>  
</template>
<script>
import api from '/src/utils/api';

const Listening = {
    name: 'Listening',
    components: {
    },
    data() {
        return {
            userList: [],
        };
    },
    mounted() {
        this.refresh();
    },
    methods: {
        refresh() {
            // console.log("Listening refresh");
            api.getCall('get-listening-list').then(userList => {
                this.userList = userList;
            });
        },
        unlisten(channelName) {
            api.postAuthRequired('unlisten-to-channel', {channelName}).then(() => {
                this.refreshAllFriendData();
            });
        },
    },
    props: ['refreshAllFriendData'],
};

export default Listening;
</script>
